export default `-----BEGIN RSA PUBLIC KEY-----
MIICCgKCAgEAhEeexsD7ywdb8M6EY8WZqOawx6sqhHxXeqJ8fHJhT72+BheuP3tn
GjrKC60PXhn20acqrp7a4qZGuIH3sM0EeZqYSDCad90S2ejfjK/3idixHB23wefc
Z0QWHnc4P6vEsWchUjGBAg3GcOnyHLu8dj5zba6MG4fTUngHiXS2rxboDNJVccFc
1zhGLHeKTdniihI+VUfZp6zbqLmQ9jiPe1jXTC22taqifaaqAay74vfB0SILEJLG
xsPn8toli2hjYQtJPcNTrDtjbA9xI34DerrukA957VfQ2hn2nKzPKWKbnKA8JtUr
rl2Y0oug+Psu+tcFkEEP3H4HggpEltZ3j3BhC/DHh4Ph53xv48eh38ltya6dUvSF
Ozd+oL7mEaUshx/3itKYd5t7jSc8WFDmv6PXnugK9R2gw2M1Ir7onjoIQTFxifeP
aA4PVeS3vLJ3A5Mjxt+94MrfxNkHZe6OHNU+ZtgE+dckvGKV4uJoqb2m6uVQSH8Z
taF7HSzwCFkR2Vvhu5k7eDMh3XcIhYbNizYjImLZ2wL14YJOUdYEKkHZX1C1qwER
CL2yaDI4PMlDMiyT5iolETbnGH6vVljUyoeZCBzSmlHz+c7zO5kfu7MsKTJeaXPs
gkfQUpf8/LTv1/Ico3YtgKquYeuwBVlW4nhrOhJQRPQP2Ey2zKZpZDsCAwEAAQ==
-----END RSA PUBLIC KEY-----`;
